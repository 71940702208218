<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Equipment Repairs" backTo="internal">
      <router-link
          :to="{ name: 'repair-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14"/>
        </svg>
        <span>Create Repair</span>
      </router-link>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-0 mr-2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected"/>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <input
                  type="text"
                  class="ml-10 w-full lg:w-1/4 rounded-l bge-input bge-input-spacing"
                  placeholder="Search "
                  v-model="searchInput"
                  @keydown.enter="triggerSearch"
              />
              <button
                  @click="triggerSearch"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <Icon iconType="search"/>
              </button>
            </div>
          </div>
        </div>


        <!-- Tab Content -->

        <TabContent :currentTab="currentTab">

          <!-- Default slot for other tabs -->
          <template v-slot:[currentTab]>
            <DataTableComponent :columns="columns" key="schedule">
              <!--              <template v-slot:timestamp-slot="{ rowData }">-->
              <!--                {{ formatTimestamp(rowData.warranty_expiry) }}-->
              <!--              </template>-->

              <template v-slot:customer-postcode-slot="{ rowData }">
                <template v-if="rowData.customer && rowData.customer.default_address">
                  {{ getDefaultAddressPostcode(rowData.customer.default_address) }}
                </template>
                <template v-else>
                </template>
              </template>
              <template v-slot:engineer-name-slot="{ rowData }">
                          <span v-if="rowData && rowData.engineer">
                          {{
                              rowData.engineer.first_name
                            }} {{ rowData.engineer.last_name }}
                         </span>
              </template>

              <template v-slot:date-received-slot="{ rowData }">
                <template v-if="rowData.created_at">
                  {{ formatTimestamp(rowData.created_at) }}
                </template>
                <template v-else>
                  PENDING
                </template>
              </template>

              <template v-slot:action-slot="{ rowData }">
                <div style="display: flex; align-items: center; gap: 8px;">


                  <AppButton v-if="rowData.repair_id" color="blue" label=""
                             :icon="true"
                             class="mr-1"
                             @click="editRepair(rowData)">
                    <template v-slot:icon>
                      <Icon iconType="edit"/>
                    </template>
                  </AppButton>
                  <AppButton v-if="rowData.quotation_id" color="green" label=""
                             :icon="true"
                             class="mr-1"
                             @click="editQuotation(rowData)">
                    <template v-slot:icon>
                      <Icon iconType="edit-quotation"/>
                    </template>
                  </AppButton>
                  <AppButton v-if="rowData.job_sheet_id" color="blue" label=""
                             :icon="true"
                             class="mr-1"
                             @click="editJobSheet(rowData)">
                    <template v-slot:icon>
                      <Icon iconType="edit-job-sheet"/>
                    </template>
                  </AppButton>

                </div>
              </template>
            </DataTableComponent>
          </template>
        </TabContent>


      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/HR/Repair/Components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
import AppButton from "@/components/Button.vue";

const Toggle = () => import("@/components/Toggle.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  mixins: [notificationMixin],
  components: {
    AppButton,
    StaffAutocomplete,
    PageHeader,
    Spinner,
    Panel,
    IsAuthorized,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
    Toggle
  },
  data() {
    return {
      tabs: [
        {
          id: "all",
          label: "All",
        },
        {id: "review", label: "Under Review",},
        {id: "quotation", label: "Quotation",},
        {id: "completed", label: "Completed",},
      ],
      columns: [
        {
          field: "customer.name",
          header: "Customer",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "customer.default_address.postcode",
          header: "Postcode",
          sortable: true,
          custom: false,
          slotName: "customer-postcode-slot",
          width: '8%',
        },
        {
          field: "product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '8%',
        },
        {
          field: "product_description",
          header: "Description",
          sortable: false,
          custom: false,
          width: '15%',
        },
        {
          field: "serial_number",
          header: "Serial Number",
          sortable: true,
          custom: false,
          width: '12%',
        },
        {
          field: "created_at",
          header: "Received",
          sortable: true,
          custom: true,
          slotName: "date-received-slot",
          width: '12%',
        },
        {
          field: "goods_in_reference_id",
          header: "Goods In Ref",
          sortable: true,
          custom: false,
          width: '12%',
        },
        {
          field: "action",
          header: "Actions",
          sortable: false,
          custom: true,
          slotName: "action-slot",
          width: '12%',
        },
      ],
    };
  },
  computed: {
    ...mapState("repair", ["currentTab", "searchText",]),

    ...mapState("repair", {
      currentTabState: (state) => state.tabs[state.currentTab],
    }),

    searchInput: {
      get() {
        return this.currentTabState.searchText;
      },
      set(value) {
        this.$store.commit("repair/UPDATE_SEARCH_TEXT", {tab: this.currentTab, searchText: value});
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("repair", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
    ]),


    getDefaultAddressPostcode(default_address) {

      return default_address ? default_address.postcode : 'N/A';
    },
    getDefaultPeoplePhone(peopleArray) {

      const defaultPhone = peopleArray.find(people => people.is_default);
      return defaultPhone ? defaultPhone.mobile || defaultPhone.telephone : 'N/A';
    },
    onRowClick(event) {

      this.Edit(event.data);
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    onTabSelected(newTab) {
      this.setTab(newTab);
    },
    setTab(newTab) {
      this.$store.dispatch("repair/updateTab", newTab);
    },
    triggerSearch() {
      this.setTab(this.currentTab);
    },

    Edit(rowObj) {
      this.$router.push({
        name: "repair-edit",
        params: {
          repair_id: rowObj.repair_id
        },
      });
    },
    editRepair(rowObj) {

      this.$router.push({
        name: "repair-edit",
        params: {
          repair_id: rowObj.repair_id
        },
      });
    },
    editQuotation(rowObj) {
      this.$router.push({
        name: "quotation-edit",
        params: {
          quotation_id: rowObj.quotation_id
        },
      });
    },
    editJobSheet(rowObj) {
      this.$router.push({
        name: "engineer-job-sheet-edit",
        params: {
          job_sheet_id: rowObj.job_sheet_id
        },
      });
    },


  },
};
</script>